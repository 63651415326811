.App {
  text-align: center;
}

.orden-row{
  height:30px;
  background-color: #ddd;

}

.orden-row-white{
  height:30px;
  background-color: white;

}

.shadowBox{
  border:none; 
  box-shadow: rgb(0 0 0 / 45%) 0px 5px 15px;
}

/*
navidad


*/

@keyframes svganimation {
  0%{
      stroke-dashoffset: 0;
  }
  50%{
      stroke-dashoffset: 1000;
  }
  100%{
      stroke-dashoffset: 0;
  }
}


html{
height: 100%;
}
 

.tree{
  border: 1px solid #d2dae2;
  background-color: #006266;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tree svg {
  /* fill: none; */
  stroke-width: 1px;
  stroke: white;
  width: 70%;
  height: 70%; 
}

.tree svg .text{
  width: 10px;
  fill: white;
}


.tree svg .p {
  stroke-dashoffset: 700;
  stroke-dasharray: 70;
  animation: svganimation 20s linear forwards infinite;
}

.tree svg .c {
  stroke-dashoffset: 1000;
  stroke-dasharray: 13;
}

.tree svg .c1 {
  animation: svganimation 100s linear forwards infinite ;
}

.tree svg .c2 {
  animation: svganimation 100s linear forwards infinite ;
  animation-delay: .05s;
}

.tree svg .c3 {
  animation: svganimation 100s linear forwards infinite ;
  animation-delay: .25s;
}

.tree svg .c4 {
  animation: svganimation 100s linear forwards infinite ;
  animation-delay: .30s;
}

.tree svg .c5 {
  animation: svganimation 100s linear forwards infinite ;
  animation-delay: .20s;
}

.tree svg .c6 {
  animation: svganimation 100s linear forwards infinite ;
  animation-delay: .10s;
}

.tree svg .c7 {
  animation: svganimation 100s linear forwards infinite ;
  animation-delay: .15s;
}




/*
navidad


*/
 

.color-red{
  color:red;
}

.width-100{
width: 100%;
}
.width-50{
  width: 50%;
  }
  .width-40{
    width: 40%;
    }
    .width-30{
      width: 30%;
      }
      .width-20{
        width: 20%;
        }
        .width-10{
          width: 10%;
          }
.width-100-px{
  width: 100px;
  }

.width-2{
  width: 2%;
}

.width-10{
    width: 10%;
}

.width-20{
  width: 20%;
}

.width-68{
  width: 68%;
}

.width-88{
  width: 88%;
}

.inpt-proveedor{
  width:97.5%; 
  height:auto;
}

.margin-auto{
  margin: auto;
}

.add-bttn{
  background-color:green; 
  color:white;
}

.remove-bttn{
  background-color:red; 
  color:white;
}
 
.addButton{
  display:flex;
  flex-direction:row;
  justify-content:end; 
  margin-top:5px;
}

.input-date{
  width: 100px;
}

.filter-date{ 
  font-size:12px; 
  cursor:pointer;
  height:auto;
  
}

.font-14{
  font-size: 14px;
}

.font-25{
  font-size: 14px;
}

.font-30{
  font-size: 30px;
}

.inpt-nva-req{
  width:100%; 
  height:auto;
}


.bttn-requisicion{
  width:55px;  
}

.sticky-header{
  position: sticky;
  top: 0; 
  background-color: #e5e5e5; 
  height: 35px;
}

.table-row{
  border-bottom: solid #e5e5e5;
}

.lista-registros-req{
  max-height:22vmax; 
  width:100%;
  margin-bottom: 80px;
}

.lista-registros{
  max-height:22vmax;  
  width:100%;
  margin-bottom: 80px;
}

.lista-registros-op{
  max-height:22vmax;  
  width:100%;
  margin-bottom: 100px;
  height: 22vmax;
}

.firma-select{
 width:95%;
 height:30.8px; 
 border: none;
 border-bottom: 1px solid black;
 text-align-last:center;
 font-size: 14px;
 color: black;
 font-weight: bold ;
}

.select-orden-pago{
  width:90%;
  height:30.8px;
  border:none;
  border-bottom: 1px solid black;
}

.firma-orden-pago{
  width: 300px;
  height: 259px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.firma-orden-pago1{
  width: 300px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.border-line{
  border: 1px solid black;
  border-radius: 3px;
}
.border-bottom1{
  border-bottom: 1px solid black;
}

.orden-cell1{
  background-color: #ddd;
  border-top:1px solid black; 
  border-left:1px solid black; 
  font-weight:bold;
}

.cell-1{
  background-color:#ddd;   
  border-bottom:1px solid black;
}

.select-req{
  width:100%; 
  height:30px;
} 
.select-req-1{
  width:100%; 
  height:30px;
}
.label-1{
  color:black; 
  font-size:32px;
}


.cell-productos{
  border-radius: 20px 20px 0px 0px; 
  height:30px; 
  font-size:16px;
}
 
.tabletr:hover{
  
    background: rgba(77, 128, 169, 0.411);

}

.w-110{
  width: 110px;
}

.w-120{
  width: 120px;
}

.w-20{
  width: 20px;
}


.slc-b{
  width:397px; 
  z-index: 100;
  position: absolute;
  display: none;
}

.names{
  padding-bottom: 10px;
}

.orden-button{
  padding-bottom:5px; 
  margin-top:10px;
}

.input-filter{
  width:100px;
  font-size:12px;  
  cursor:pointer;
}

.input-1{
  width:120px; 
  background-color:transparent; 
  border:none; 
  text-align:center;
}

.div-section1{
  overflow-y:scroll; 
  width:100%;
}
.div-section2{
  display: flex; 
  flex-direction: column;
}
.div-section-row{
  display: flex; 
  flex-direction: row;
}
.input-hidden{
  display:none;
  height:auto;
}

.orden-firma{
  border: 1px solid black;
  flex-direction:column; 
  flex: 1; 
  background-color:white; 
  margin-bottom:2px;
}

.orden-cell2{
  background-color: #ddd;
  border-top:1px solid black; 
  border-right:1px solid black; 
  border-left:1px solid black; 
}
.orden-input1{
  width:85%; 
  background-color:transparent; 
  border:none; 
  text-align:right;
  height:auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 
.toastProgress {
  background: #0071ce !important;
}
 

.Bttn{
  background-color: white;
  border: 1px solid black;
  color: blak;
  border-radius: 3px;
  
}

.Bttn:hover{
background-color: blue;
color: white;
}


.select-editable {position:relative; background-color:white; border: none; width:100%;  }
.select-editable select {position:absolute; top:0px; left:0px;   border:none; width:100%; margin:0;}
.select-editable input {position:absolute; top:0px; left:0px; width:95.5%; height: 24px; border:none;}
.select-editable select:focus, .select-editable input:focus {outline:none;}

.select-editable1 {position:relative; background-color:white; border: none; width:100%;  }
.select-editable1 select {position:absolute; top:0px; left:0px;   border:none; width:100%; margin:0;}
.select-editable1 input {position:absolute; top:0px; left:0px; width:95.5%; height: 24px; border:none;}
.select-editable1 select2:focus, .select-editable1 input:focus {outline:none;}

.select-editable2 {position:relative; background-color:white; border: none; width:100%;  }
.select-editable2 select {position:absolute; top:0px; left:0px;   border:none; width:100%; margin:0;}
.select-editable2 input {position:absolute; top:0px; left:0px; width:95.5%; height: 24px; }
.select-editable2 select:focus, .select-editable input:focus {outline:none;}

.blink_me { 
  animation: blinker 1s linear infinite;
  font-weight: 600; 
  color: red;
} 
 
@keyframes blinker { 
  50% { 
    opacity: 0; 
  } 
}

.Bttns{
  background-color: red;
  border: 1px solid red;
  color: white;
  border-radius: 3px;
  
}

.Bttns:hover{
background-color: green;
color: white;
}

#div-ordenpago1{
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 2vw;
  background-color: white;
  border: 2px solid black;
  border-radius: 3px;
}
.underlined{
  border: none; 
  border-bottom: 1px solid black;
}
#header-op{
  display: flex;
  margin-bottom: 1.5vmax;
}
#header-logo{
  width: 5vmax;
}
#header-labels{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#tbl-ordenpago{
  width: match-parent;
}
#firstcolumn{
  width: 15%;
}
#secondcolumn{
  width: 35%;
}
#thirdcolumn{
  width: 14%;
}
#fourthcolumn{
  width: 35%;
}
#footer-op{
  display: flex;
  margin-top: 1.5vmax;
  justify-content: space-between;
}
#footer-solicito{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 
.jcend{
  justify-content: end;
}
.mtop5{
  margin-top: 5px;
}
.padding2{
  padding: 2px 2px;
}
.bggreen{
  background-color: green;
}
#footer-autorizo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#footer-op label{
  font-weight: bold;
}
#tbl-ordenpago input{
  width: 100%;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: none;
}
#tbl-ordenpago label{
  font-weight: bold;
}
.labeltoright{
  float: right;
}
#header-empresa{
  font-weight: bold;
  font-size: 20px;
}
#header-rzonsocial1{
  font-weight: bold;
  font-size: 25px;
}

 

.btncancelar:hover{
  background-color: red;
  color: white;
  border-color: black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.DivConfirmacion{
  
  color: black;
  width: 400px;
    height: 120px;
    border: 3px solid red;
    border-radius: 6px;
    background-color: #D5DFE8;
    box-shadow: 3px 3px rgb(209, 208, 208);
    padding-top: 10px;
    padding-bottom: 13px;
    align-self: center;
    top: 270px;
    left: 40%;
    position: fixed;
    font-size: 16px;
    align-content: center;
    text-align: center;
    font-weight: bold;
}
.Aceptar{
  width: 80px;
  height: 30px;
  background-color: green;
  border: 1px solid white;
  color: white;
  margin-top: 10px;
  margin-right: 7px;
  border-radius: 3px;

}
.Cancelar{
  width: 80px;
  height: 30px;
  background-color: red;
  border: 1px solid white;
  color: white;
  margin-top: 10px;
  margin-right: 7px;
  border-radius: 3px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#divtable-ordenes{ 
  max-height:22vmax; 
  margin-bottom: 130px; 
}
@media all and (min-width:321px) and (max-width: 699px) { 
  
  #requisiciones{
    min-width: 1060px;
  }

  #nvarequisicion{
    min-width: 1060px; 
  }

  #productstable{
    min-width: 1060px; 
  }

  #divtable-ordenes{
    min-width: 1060px;
    
  }

  #div-requisiones{
    min-width: 1060px;
  }



  #div-orden-compra{
    min-width: 1060px;
  }

   

  #productstable-requisiones{
    min-width: 690px;
  }

  #navordenes{
    min-width: 1060px;
  }
  #navrequisiciones{
    min-width: 1060px;
  }
  #navnuevarequisicion{
    min-width: 690px;
  }

  #div-ordenpago1{
    min-width: 1080px;
  }
  #div-ordenpago{
    min-width: 1080px;
  }
  #navOrdpago{
    min-width: 1080px;
  }
  #productstable-Ordpago{
    min-width: 1080px;
  }
  #div-Ordpago{
    min-width: 1080px;
  }
  #btnOrdpago{
    min-width: 1080px;
  }
  #btnOrdpagoLimpiar{
    min-width: 1080px;
  }
  #btn-guardar{
    min-width: 1080px;
  }
  #btn-imprimir{
    min-width: 1080px;
  }
}
/*
#departamento:before{
  content: 'ORDEN CANCELADA';
  position:absolute; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  
  color: red;
  font-size: 100px;
  font-weight: 500px;
  display: grid;
  justify-content: center;
  align-content: center;
  opacity: 0.5;
}
*/

.ocancelada img { width: 100%; }
.ocancelada { position: relative; }
.ocancelada::before {
  content: "ORDEN CANCELADA";
  position: absolute;
  display: grid;
  justify-content: center;
  align-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  font-size: 100px;
  font-weight: 500px;
  color: red;
  z-index: 1;
  -webkit-transform: rotate(-45deg); 
     -moz-transform: rotate(-45deg); 
      -ms-transform: rotate(-45deg); 
       -o-transform: rotate(-45deg); 
          transform: rotate(-45deg);
   -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865482, M12=0.7071067811865466, M21=-0.7071067811865466, M22=0.7071067811865482, SizingMethod='auto expand')";
   filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865482, M12=0.7071067811865466, M21=-0.7071067811865466, M22=0.7071067811865482, SizingMethod='auto expand');
}


.vistaPrevia img { width: 100%; }
.vistaPrevia { position: relative; }
.vistaPrevia::before {
  content: "VISTA PREVIA";
  position: absolute;
  display: grid;
  justify-content: center;
  align-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  font-size: 100px;
  font-weight: 500px;
  color: grey;
  z-index: 1;
  -webkit-transform: rotate(-45deg); 
     -moz-transform: rotate(-45deg); 
      -ms-transform: rotate(-45deg); 
       -o-transform: rotate(-45deg); 
          transform: rotate(-45deg);
   -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865482, M12=0.7071067811865466, M21=-0.7071067811865466, M22=0.7071067811865482, SizingMethod='auto expand')";
   filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865482, M12=0.7071067811865466, M21=-0.7071067811865466, M22=0.7071067811865482, SizingMethod='auto expand');
}
.no-after::before{content:none;}
#sideimg1{
  content: url("./resources/account_circle_white_24dp.svg");
  height: 26px;
}
#sideimg11{
  content: url("./resources/proveedores.svg");
  height: 26px;
}

#sideimg2{
  content: url("./resources/request_page_white_24dp.svg");
  height: 26px;
}

#sideimg3{
  content: url("./resources/add.svg");
  height: 26px;
}

#sideimg4{
  content: url("./resources/request_page_white_24dp.svg");
  height: 26px;
}

#sideimg5{
  content: url("./resources/logout.svg");
  height: 26px;
}

#sideimg6{
  content: url("./resources/fact_check_white_24dp.svg");
  height: 26px;
}

#sideimg7{
  content: url("./resources/fact_check_white_24dp.svg");
  height: 26px;
}

#sideimg8{
  content: url("./resources/fact_check_white_24dp.svg");
  height: 26px;
}

#sideimg9{
  content: url("./resources/shopping.svg");
  height: 26px;
}


#sideimg91{
  content: url("./resources/solicitudpago.png");
  height: 26px;
}

#sideimg10{
  content: url("./resources/request.svg");
  height: 26px;
}


#sideimg12{
  content: url("./resources/money-send-svgrepo-com.svg");
  height: 26px;
}




.sideselected{
  background-color: #001f6b;
}

#expand{
  content: url("./resources/expand_more_black_24dp.svg");
  height: 26px;
}

/* Position and sizing of burger button */
.bm-burger-button {
position: fixed;
width: 36px;
height: 30px;
left: 36px;
top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
height: 24px;
width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
position: fixed;
height: 100%;
}

/* General sidebar styles */
.bm-menu {
background: #373a47; 
font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
color: #b8b7ad;
padding: 0.0em;
}

/* Individual item */
.bm-item {
display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
background: rgba(0, 0, 0, 0.3);
}



body{
  font-family: 'Roboto', sans-serif; 
  background-image: linear-gradient(white, #ddd); 
  background-repeat: no-repeat;
  background-size:auto; 
  justify-content: center;
}

#body-content{
  display: flex; 
  margin: auto; 
  margin-top: 15vh;
  margin-bottom: 15vh;
  background-color: #fff; 
  width: 60vmax; border-radius: 2px; 
  align-self: center;
  height: 70vh;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

#div-img{
  display: flex; 
  flex: 1;  
  justify-content: center; 
  padding: 2vmax; 
  max-width: 50%;
}


#div-form{
  display: flex; 
  flex: 1; 
  flex-direction: column; 
  padding: 2vmax; 
  justify-content: center;
}

#div-form span{
  margin-bottom: .5vmax; 
  font-size: 14px; 
  font-weight: bold;
}

#div-form input{
  height: 2vmax; 
  margin-bottom: 3vmax;
}

#form-btn{
  border: none; 
  height: 3vmax; 
  border-radius: 5px; 
  align-self: center; 
  margin-top: 2vmax;
  transition: background-color .15s;
  background-color: #e0e1e2;
}

#form-btn:hover{
  background-color: #cacbcd;
  cursor: pointer;
}


#sidepanel{
  display: flex;
  font-family: 'Roboto', sans-serif; 
  font-size: 12px;
  flex-direction: column; 
  /* max-height: 100vh; */
  height: 100vh;
  width: 15vmax;
  padding: 0 .3vmax;
  position: sticky;
  top: 0;
}

#sidebtn{
  color: white;
  transition: background-color .2s;
  border-bottom: 2px solid whot;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  text-align: center;
  height: auto;
  justify-content: center;
  padding: 1vmin 0px;
  align-items: center;
  
}

.btn-orden-pago-f{
  padding-bottom:5px; 
  margin-top:10px; 
}

.txtCenter{
  text-align:center;
}

.slc-tarjeta-op{
  width:100%;
  height:30.8px; 
  border:none;
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.inp-border-n{
  border: none;
}

.slc-op-firmas{
  width:90%;
  height:30.8px; 
  border:none; 
  border-bottom: 1px solid black;
}

.inpt-password{
   color:white; 
   height: 45px;
}

.form-password{
  display: flex; 
  flex-direction: column; 
  width: 25%; 
  margin-left:2%;
}

.border-1{
  border:1px solid black;
}


#errorContainer{
  width: 100%;
  text-align: center; 
  margin: auto;
}

#mainContainer{
  height: 100vh; 
  width: 100vw; 
  top: 0; 
  position: sticky; 
  display: flex; 
  overflow-x: auto;
}

#sidebtn span{
  flex: 1;
}

#sidepanel div:hover{
  background-color: #001f6b;
  cursor: pointer;
}
#top-menu{
  display: block;
  width: 100%;
  text-align: center;
  color: white; 
  font-Size: 12px;
}
#sidepanel-logo{
  content: url("./resources/favicon.svg");
  max-height: 150px; 
  max-width: 150px; 
  align-self: center;
  padding-top: 1vh;
  padding-left: 1vh;
  margin-left: 25%;
}
   

@media all and (min-width:321px) and (max-width: 699px) { 

  #form-btn{ 
      min-height: 45px; 
  }
  #form-usuario{ 
      min-width: 100%; 
  }
  #div-img{
      max-width: 100%; 
      width: 100%;
  }

   #form-password{ 
      min-width: 100%;
  }
  #select{ 
      min-width: 100%;
  }
  

  #body-content{ 
      display: flex; 
      flex-direction: column;  
      margin: auto;  
      margin-top: 10vh; 
      margin-bottom: 15vh; 
      background-color: #fff;  
      width: 100%;  
      border-radius: 2px;  
      align-self: center; 
      height: 70vh; 
      box-shadow: 0 10px 20px #fff, 0 6px 6px #fff; 
  } 
  
}